body {
    left: 0;
    bottom: 0;
}
#root {
    height: 10px;
}

/* Hover labels */
.hover-underline-animation-light,
.hover-underline-animation-dark {
    display: inline-block;
    position: relative;
}

.hover-underline-animation-light:after,
.hover-underline-animation-dark:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;
    transform-origin: bottom right;
    transition: transform 0.2s ease-out;
}

.hover-underline-animation-light:after {
    background-color: black;
}

.hover-underline-animation-dark:after {
    background-color: white;
}

.hover-underline-animation-light:hover:after,
.hover-underline-animation-dark:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/* Icon Animations */
.gelatine:hover {
    animation: gelatine 0.75s infinite;
    animation-iteration-count: 1;
}

@keyframes gelatine {
    from,
    to {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(0.9, 1.1);
    }

    50% {
        transform: scale(1.1, 0.9);
    }

    75% {
        transform: scale(0.95, 1.05);
    }
}

.wave:hover {
    animation: wave 1s ease;
}

@keyframes wave {
    30% {
        transform: scale(1.2);
    }
    40%,
    60% {
        transform: rotate(-20deg) scale(1.2);
    }
    50% {
        transform: rotate(20deg) scale(1.2);
    }
    70% {
        transform: rotate(0deg) scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

/* Scroll Arrow Animation */
.scrollDownContainer {
    position:relative;
    animation: glide 1s ease-in-out alternate infinite;
}

@keyframes glide {
    from {
        left: 0px;
        top: 0px;
    }

    to {
        left: 0px;
        top: 10px;
    }
}

.subPageButtonArrow {
    position: relative;
    animation: nudgeRight 1s ease-in-out infinite
}

@keyframes nudgeRight {
    0% {
        left: 0px;
        top: 0px;
    }
    50% {
        left: 2.5px;
        top: 0px;
    }
    100% {
        left: 0px;
        top: 0px;
    }
}

.downloadArrow {
    position: relative;
    animation: nudgeDown 1s ease-in-out infinite
}

@keyframes nudgeDown {
    0% {
        left: 0px;
        top: 0px;
    }

    50% {
        left: 0px;
        top: 2.5px;
    }

    100% {
        left: 0px;
        top: 0px;
    }
}
